<template>
  <div>
    <div class="about_page-main">
      <div id="container" style="width:100%;height:100vh;"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      map: null,
      dot: null,
      dotAnimation: null,
      docAnimationData: [],
    };
  },
  created() {
    const that = this
    this.$nextTick(() => {
      that.initMap().then(val => {
        if (val) {
          that.initDotData()
        }
      })
    })
  },
  methods: {
    initMap() {
      const that = this
      // 初始化地图
      that.map = new that.$Map.Map('container', {
        center: new that.$Map.LatLng(37.203973, 120.793906), //设置地图中心点坐标
        zoom: 9,
        mapStyleId: "style4", //个性化样式20
        baseMap: {
          type: "vector",
          features: ["base", "building3d"], // 隐藏矢量文字
        },
        renderOptions: {
          enableBloom: true, // 泛光
        },
      })

      return Promise.resolve(that.map)
    },
    initDot() {
      const that = this
      that.dot = new that.$Map.visualization.Dot({
        faceTo: "map", //散点固定的朝向
        styles: {
          style1: {
            fillColor: "#FFCA1F",
            radius: 1,
          },
          style2: {
            fillColor: "#C72A18",
            radius: 2,
          },
        },
        enableBloom: true
      }).addTo(that.map).setData(that.docAnimationData)

      that.dotAnimation = new that.$Map.visualization.Dot({
        faceTo: "map", //散点固定的朝向
        styles: {
          style1: {
            type: "circle", //圆形样式
            fillColor: "rgba(255,202,31,0.3)",
            radius: 12,
          },
          style2: {
            type: "circle", //圆形样式
            fillColor: "rgba(204,42,24,0.3)",
            radius: 14,
          },
        },
        processAnimation: {
          animationType: "radiated", //动画类型
          enableFade: true
        },
      }).addTo(that.map).setData(that.docAnimationData)
    },
    initDotData() {
      const that = this
      const locData = JSON.parse(localStorage.getItem('docAnimationData'))
      if(locData) {
        that.docAnimationData = locData
        that.initDot()
      } else {
        axios.get('https://show.amob.cn/dpBusiness/dpBusiness/findAll',{params: {mdszshq: '山东'},headers: {'Content-Type': 'application/json'}}).then(res => {
          const resData = res.data.data
          resData.forEach((item,index) => {
            const dotData = {lat: Number(item.atitude), lng: Number(item.longitude), styleId: index > 500 ? "style2" : "style1"}
            that.docAnimationData.push(dotData)
          })
          const setData = JSON.stringify(that.docAnimationData)
          localStorage.setItem('docAnimationData', setData)
          that.initDot()
        })
      }
    }
  }
};
</script>

<style lang="scss">
</style>
